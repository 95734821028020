/** This file was automatically generated. DO NOT MODIFY IT BY HAND. */
const overrides = {};

export const cssPropertyToDefaultValueMap: { [key: string]: string } = {
  ...overrides,
  '--*': 'revert',
  '-ms-accelerator': 'false',
  '-ms-block-progression': 'tb',
  '-ms-content-zoom-chaining': 'none',
  '-ms-content-zooming': 'zoomForTheTopLevelNoneForTheRest',
  '-ms-content-zoom-limit': '400% 100%',
  '-ms-content-zoom-limit-max': '400%',
  '-ms-content-zoom-limit-min': '100%',
  '-ms-content-zoom-snap': 'none snapInterval(0%, 100%)',
  '-ms-content-zoom-snap-points': 'snapInterval(0%, 100%)',
  '-ms-content-zoom-snap-type': 'none',
  '-ms-filter': '&quot;&quot;',
  '-ms-flow-from': 'none',
  '-ms-flow-into': 'none',
  '-ms-grid-columns': 'none',
  '-ms-grid-rows': 'none',
  '-ms-high-contrast-adjust': 'revert',
  '-ms-hyphenate-limit-chars': 'revert',
  '-ms-hyphenate-limit-lines': 'revert',
  '-ms-hyphenate-limit-zone': 'revert',
  '-ms-ime-align': 'auto',
  '-ms-overflow-style': 'revert',
  '-ms-scrollbar-3dlight-color': 'revert',
  '-ms-scrollbar-arrow-color': 'revert',
  '-ms-scrollbar-base-color': 'revert',
  '-ms-scrollbar-darkshadow-color': 'revert',
  '-ms-scrollbar-face-color': 'revert',
  '-ms-scrollbar-highlight-color': 'revert',
  '-ms-scrollbar-shadow-color': 'revert',
  '-ms-scrollbar-track-color': 'revert',
  '-ms-scroll-chaining': 'chained',
  '-ms-scroll-limit': '0 0 auto auto',
  '-ms-scroll-limit-x-max': 'auto',
  '-ms-scroll-limit-x-min': '0',
  '-ms-scroll-limit-y-max': 'auto',
  '-ms-scroll-limit-y-min': '0',
  '-ms-scroll-rails': 'railed',
  '-ms-scroll-snap-points-x': 'snapInterval(0px, 100%)',
  '-ms-scroll-snap-points-y': 'snapInterval(0px, 100%)',
  '-ms-scroll-snap-type': 'none',
  '-ms-scroll-snap-x': 'none snapInterval(0px, 100%)',
  '-ms-scroll-snap-y': 'none snapInterval(0px, 100%)',
  '-ms-scroll-translation': 'revert',
  '-ms-text-autospace': 'none',
  '-ms-touch-select': 'revert',
  '-ms-user-select': 'text',
  '-ms-wrap-flow': 'auto',
  '-ms-wrap-margin': '0',
  '-ms-wrap-through': 'wrap',
  '-moz-appearance': 'noneButOverriddenInUserAgentCSS',
  '-moz-binding': 'none',
  '-moz-border-bottom-colors': 'none',
  '-moz-border-left-colors': 'none',
  '-moz-border-right-colors': 'none',
  '-moz-border-top-colors': 'none',
  '-moz-context-properties': 'revert',
  '-moz-float-edge': 'content-box',
  '-moz-force-broken-image-icon': '0',
  '-moz-image-region': 'revert',
  '-moz-orient': 'inline',
  '-moz-outline-radius': '0 0 0 0',
  '-moz-outline-radius-bottomleft': '0',
  '-moz-outline-radius-bottomright': '0',
  '-moz-outline-radius-topleft': '0',
  '-moz-outline-radius-topright': '0',
  '-moz-stack-sizing': 'revert',
  '-moz-text-blink': 'none',
  '-moz-user-focus': 'none',
  '-moz-user-input': 'revert',
  '-moz-user-modify': 'revert',
  '-moz-window-dragging': 'drag',
  '-moz-window-shadow': 'default',
  '-webkit-appearance': 'noneButOverriddenInUserAgentCSS',
  '-webkit-border-before': 'revert',
  '-webkit-border-before-color': 'revert',
  '-webkit-border-before-style': 'revert',
  '-webkit-border-before-width': 'revert',
  '-webkit-box-reflect': 'none',
  '-webkit-line-clamp': 'none',
  '-webkit-mask': 'none repeat scroll 0% 0% padding border',
  '-webkit-mask-attachment': 'scroll',
  '-webkit-mask-clip': 'border',
  '-webkit-mask-composite': 'source-over',
  '-webkit-mask-image': 'none',
  '-webkit-mask-origin': 'padding',
  '-webkit-mask-position': '0% 0%',
  '-webkit-mask-position-x': '0%',
  '-webkit-mask-position-y': '0%',
  '-webkit-mask-repeat': 'repeat',
  '-webkit-mask-repeat-x': 'repeat',
  '-webkit-mask-repeat-y': 'repeat',
  '-webkit-mask-size': 'auto auto',
  '-webkit-overflow-scrolling': 'revert',
  '-webkit-tap-highlight-color': 'revert',
  '-webkit-text-fill-color': 'revert',
  '-webkit-text-stroke': 'revert',
  '-webkit-text-stroke-color': 'revert',
  '-webkit-text-stroke-width': 'revert',
  '-webkit-touch-callout': 'revert',
  '-webkit-user-modify': 'revert',
  'accent-color': 'revert',
  'align-content': 'normal',
  'align-items': 'normal',
  'align-self': 'auto',
  'align-tracks': 'normal',
  all: 'noPracticalInitialValue',
  'anchor-name': 'none',
  'anchor-scope': 'none',
  animation: 'none 0s ease 0s 1 normal none running auto',
  'animation-composition': 'replace',
  'animation-delay': '0s',
  'animation-direction': 'normal',
  'animation-duration': '0s',
  'animation-fill-mode': 'none',
  'animation-iteration-count': '1',
  'animation-name': 'none',
  'animation-play-state': 'running',
  'animation-range': 'normal normal',
  'animation-range-end': 'normal',
  'animation-range-start': 'normal',
  'animation-timing-function': 'ease',
  'animation-timeline': 'auto',
  appearance: 'none',
  'aspect-ratio': 'auto',
  azimuth: 'revert',
  'backdrop-filter': 'none',
  'backface-visibility': 'visible',
  background:
    'none 0% 0% auto auto repeat padding-box border-box scroll transparent',
  'background-attachment': 'scroll',
  'background-blend-mode': 'normal',
  'background-clip': 'border-box',
  'background-color': 'transparent',
  'background-image': 'none',
  'background-origin': 'padding-box',
  'background-position': '0% 0%',
  'background-position-x': '0%',
  'background-position-y': '0%',
  'background-repeat': 'repeat',
  'background-size': 'auto auto',
  'block-size': 'auto',
  border: 'medium none currentcolor',
  'border-block': 'medium none currentcolor',
  'border-block-color': 'currentcolor',
  'border-block-style': 'none',
  'border-block-width': 'medium',
  'border-block-end': 'medium none currentcolor',
  'border-block-end-color': 'currentcolor',
  'border-block-end-style': 'none',
  'border-block-end-width': 'medium',
  'border-block-start': 'medium none revert',
  'border-block-start-color': 'currentcolor',
  'border-block-start-style': 'none',
  'border-block-start-width': 'medium',
  'border-bottom': 'medium none currentcolor',
  'border-bottom-color': 'currentcolor',
  'border-bottom-left-radius': '0',
  'border-bottom-right-radius': '0',
  'border-bottom-style': 'none',
  'border-bottom-width': 'medium',
  'border-collapse': 'revert',
  'border-color': 'currentcolor currentcolor currentcolor currentcolor',
  'border-end-end-radius': '0',
  'border-end-start-radius': '0',
  'border-image': 'none 100% 1 0 stretch',
  'border-image-outset': '0',
  'border-image-repeat': 'stretch',
  'border-image-slice': '100%',
  'border-image-source': 'none',
  'border-image-width': '1',
  'border-inline': 'medium none currentcolor',
  'border-inline-end': 'medium none revert',
  'border-inline-color': 'currentcolor',
  'border-inline-style': 'none',
  'border-inline-width': 'medium',
  'border-inline-end-color': 'currentcolor',
  'border-inline-end-style': 'none',
  'border-inline-end-width': 'medium',
  'border-inline-start': 'medium none revert',
  'border-inline-start-color': 'currentcolor',
  'border-inline-start-style': 'none',
  'border-inline-start-width': 'medium',
  'border-left': 'medium none currentcolor',
  'border-left-color': 'currentcolor',
  'border-left-style': 'none',
  'border-left-width': 'medium',
  'border-radius': '0 0 0 0',
  'border-right': 'medium none currentcolor',
  'border-right-color': 'currentcolor',
  'border-right-style': 'none',
  'border-right-width': 'medium',
  'border-spacing': 'revert',
  'border-start-end-radius': '0',
  'border-start-start-radius': '0',
  'border-style': 'none none none none',
  'border-top': 'medium none currentcolor',
  'border-top-color': 'currentcolor',
  'border-top-left-radius': '0',
  'border-top-right-radius': '0',
  'border-top-style': 'none',
  'border-top-width': 'medium',
  'border-width': 'medium medium medium medium',
  bottom: 'auto',
  'box-align': 'stretch',
  'box-decoration-break': 'slice',
  'box-direction': 'normal',
  'box-flex': '0',
  'box-flex-group': '1',
  'box-lines': 'single',
  'box-ordinal-group': '1',
  'box-orient': 'inlineAxisHorizontalInXUL',
  'box-pack': 'start',
  'box-shadow': 'none',
  'box-sizing': 'content-box',
  'break-after': 'auto',
  'break-before': 'auto',
  'break-inside': 'auto',
  'caption-side': 'revert',
  caret: 'revert',
  'caret-color': 'revert',
  'caret-shape': 'revert',
  clear: 'none',
  clip: 'auto',
  'clip-path': 'none',
  color: 'revert',
  'color-scheme': 'revert',
  'column-count': 'auto',
  'column-fill': 'balance',
  'column-gap': 'normal',
  'column-rule': 'medium none currentcolor',
  'column-rule-color': 'currentcolor',
  'column-rule-style': 'none',
  'column-rule-width': 'medium',
  'column-span': 'none',
  'column-width': 'auto',
  columns: 'auto auto',
  contain: 'none',
  'contain-intrinsic-size': 'none none',
  'contain-intrinsic-block-size': 'none',
  'contain-intrinsic-height': 'none',
  'contain-intrinsic-inline-size': 'none',
  'contain-intrinsic-width': 'none',
  container: 'none normal',
  'container-name': 'none',
  'container-type': 'normal',
  content: 'normal',
  'content-visibility': 'visible',
  'counter-increment': 'none',
  'counter-reset': 'none',
  'counter-set': 'none',
  cursor: 'revert',
  direction: 'revert',
  display: 'inline',
  'empty-cells': 'revert',
  'field-sizing': 'fixed',
  filter: 'none',
  flex: '0 1 auto',
  'flex-basis': 'auto',
  'flex-direction': 'row',
  'flex-flow': 'row nowrap',
  'flex-grow': '0',
  'flex-shrink': '1',
  'flex-wrap': 'nowrap',
  float: 'none',
  font: 'revert',
  'font-family': 'revert',
  'font-feature-settings': 'revert',
  'font-kerning': 'revert',
  'font-language-override': 'revert',
  'font-optical-sizing': 'revert',
  'font-palette': 'revert',
  'font-variation-settings': 'revert',
  'font-size': 'revert',
  'font-size-adjust': 'revert',
  'font-smooth': 'revert',
  'font-stretch': 'revert',
  'font-style': 'revert',
  'font-synthesis': 'revert',
  'font-synthesis-position': 'revert',
  'font-synthesis-small-caps': 'revert',
  'font-synthesis-style': 'revert',
  'font-synthesis-weight': 'revert',
  'font-variant': 'revert',
  'font-variant-alternates': 'revert',
  'font-variant-caps': 'revert',
  'font-variant-east-asian': 'revert',
  'font-variant-emoji': 'revert',
  'font-variant-ligatures': 'revert',
  'font-variant-numeric': 'revert',
  'font-variant-position': 'revert',
  'font-weight': 'revert',
  'forced-color-adjust': 'revert',
  gap: 'normal normal',
  grid: 'none none none auto auto row 0 0 normal normal',
  'grid-area': 'auto auto auto auto',
  'grid-auto-columns': 'auto',
  'grid-auto-flow': 'row',
  'grid-auto-rows': 'auto',
  'grid-column': 'auto auto',
  'grid-column-end': 'auto',
  'grid-column-gap': '0',
  'grid-column-start': 'auto',
  'grid-gap': '0 0',
  'grid-row': 'auto auto',
  'grid-row-end': 'auto',
  'grid-row-gap': '0',
  'grid-row-start': 'auto',
  'grid-template': 'none none none',
  'grid-template-areas': 'none',
  'grid-template-columns': 'none',
  'grid-template-rows': 'none',
  'hanging-punctuation': 'revert',
  height: 'auto',
  'hyphenate-character': 'revert',
  'hyphenate-limit-chars': 'revert',
  hyphens: 'revert',
  'image-orientation': 'revert',
  'image-rendering': 'revert',
  'image-resolution': 'revert',
  'ime-mode': 'auto',
  'initial-letter': 'normal',
  'initial-letter-align': 'auto',
  'inline-size': 'auto',
  'input-security': 'auto',
  inset: 'auto auto auto auto',
  'inset-area': 'none',
  'inset-block': 'auto auto',
  'inset-block-end': 'auto',
  'inset-block-start': 'auto',
  'inset-inline': 'auto auto',
  'inset-inline-end': 'auto',
  'inset-inline-start': 'auto',
  isolation: 'auto',
  'justify-content': 'normal',
  'justify-items': 'legacy',
  'justify-self': 'auto',
  'justify-tracks': 'normal',
  left: 'auto',
  'letter-spacing': 'revert',
  'line-break': 'revert',
  'line-clamp': 'none',
  'line-height': 'revert',
  'line-height-step': 'revert',
  'list-style': 'revert',
  'list-style-image': 'revert',
  'list-style-position': 'revert',
  'list-style-type': 'revert',
  margin: '0 0 0 0',
  'margin-block': '0 0',
  'margin-block-end': '0',
  'margin-block-start': '0',
  'margin-bottom': '0',
  'margin-inline': '0 0',
  'margin-inline-end': '0',
  'margin-inline-start': '0',
  'margin-left': '0',
  'margin-right': '0',
  'margin-top': '0',
  'margin-trim': 'none',
  mask: 'none match-source repeat 0% 0% border-box border-box auto add',
  'mask-border': 'alpha 0 stretch 0 none auto',
  'mask-border-mode': 'alpha',
  'mask-border-outset': '0',
  'mask-border-repeat': 'stretch',
  'mask-border-slice': '0',
  'mask-border-source': 'none',
  'mask-border-width': 'auto',
  'mask-clip': 'border-box',
  'mask-composite': 'add',
  'mask-image': 'none',
  'mask-mode': 'match-source',
  'mask-origin': 'border-box',
  'mask-position': '0% 0%',
  'mask-repeat': 'repeat',
  'mask-size': 'auto',
  'mask-type': 'luminance',
  'masonry-auto-flow': 'pack',
  'math-depth': 'revert',
  'math-shift': 'revert',
  'math-style': 'revert',
  'max-block-size': 'none',
  'max-height': 'none',
  'max-inline-size': 'none',
  'max-lines': 'none',
  'max-width': 'none',
  'min-block-size': '0',
  'min-height': 'auto',
  'min-inline-size': '0',
  'min-width': 'auto',
  'mix-blend-mode': 'normal',
  'object-fit': 'fill',
  'object-position': 'revert',
  offset: 'normal none 0 auto auto',
  'offset-anchor': 'auto',
  'offset-distance': '0',
  'offset-path': 'none',
  'offset-position': 'normal',
  'offset-rotate': 'auto',
  opacity: '1',
  order: '0',
  orphans: 'revert',
  outline: 'auto none medium',
  'outline-color': 'auto',
  'outline-offset': '0',
  'outline-style': 'none',
  'outline-width': 'medium',
  overflow: 'visible',
  'overflow-anchor': 'auto',
  'overflow-block': 'auto',
  'overflow-clip-box': 'padding-box',
  'overflow-clip-margin': '0px',
  'overflow-inline': 'auto',
  'overflow-wrap': 'revert',
  'overflow-x': 'visible',
  'overflow-y': 'visible',
  overlay: 'none',
  'overscroll-behavior': 'auto',
  'overscroll-behavior-block': 'auto',
  'overscroll-behavior-inline': 'auto',
  'overscroll-behavior-x': 'auto',
  'overscroll-behavior-y': 'auto',
  padding: '0 0 0 0',
  'padding-block': '0 0',
  'padding-block-end': '0',
  'padding-block-start': '0',
  'padding-bottom': '0',
  'padding-inline': '0 0',
  'padding-inline-end': '0',
  'padding-inline-start': '0',
  'padding-left': '0',
  'padding-right': '0',
  'padding-top': '0',
  page: 'auto',
  'page-break-after': 'auto',
  'page-break-before': 'auto',
  'page-break-inside': 'auto',
  'paint-order': 'revert',
  perspective: 'none',
  'perspective-origin': '50% 50%',
  'place-content': 'normal normal',
  'place-items': 'normal legacy',
  'place-self': 'auto auto',
  'pointer-events': 'revert',
  position: 'static',
  'position-anchor': 'implicit',
  'position-try': 'none normal',
  'position-try-options': 'none',
  'position-try-order': 'normal',
  'position-visibility': 'anchors-visible',
  'print-color-adjust': 'revert',
  quotes: 'revert',
  resize: 'none',
  right: 'auto',
  rotate: 'none',
  'row-gap': 'normal',
  'ruby-align': 'revert',
  'ruby-merge': 'revert',
  'ruby-position': 'revert',
  scale: 'none',
  'scrollbar-color': 'revert',
  'scrollbar-gutter': 'auto',
  'scrollbar-width': 'auto',
  'scroll-behavior': 'auto',
  'scroll-margin': '0 0 0 0',
  'scroll-margin-block': '0 0',
  'scroll-margin-block-start': '0',
  'scroll-margin-block-end': '0',
  'scroll-margin-bottom': '0',
  'scroll-margin-inline': '0 0',
  'scroll-margin-inline-start': '0',
  'scroll-margin-inline-end': '0',
  'scroll-margin-left': '0',
  'scroll-margin-right': '0',
  'scroll-margin-top': '0',
  'scroll-padding': 'auto auto auto auto',
  'scroll-padding-block': 'auto auto',
  'scroll-padding-block-start': 'auto',
  'scroll-padding-block-end': 'auto',
  'scroll-padding-bottom': 'auto',
  'scroll-padding-inline': 'auto auto',
  'scroll-padding-inline-start': 'auto',
  'scroll-padding-inline-end': 'auto',
  'scroll-padding-left': 'auto',
  'scroll-padding-right': 'auto',
  'scroll-padding-top': 'auto',
  'scroll-snap-align': 'none',
  'scroll-snap-coordinate': 'none',
  'scroll-snap-destination': '0px 0px',
  'scroll-snap-points-x': 'none',
  'scroll-snap-points-y': 'none',
  'scroll-snap-stop': 'normal',
  'scroll-snap-type': 'none',
  'scroll-snap-type-x': 'none',
  'scroll-snap-type-y': 'none',
  'scroll-timeline': 'none block',
  'scroll-timeline-axis': 'block',
  'scroll-timeline-name': 'none',
  'shape-image-threshold': '0.0',
  'shape-margin': '0',
  'shape-outside': 'none',
  'tab-size': 'revert',
  'table-layout': 'auto',
  'text-align': 'revert',
  'text-align-last': 'revert',
  'text-combine-upright': 'revert',
  'text-decoration': 'currentcolor solid none',
  'text-decoration-color': 'currentcolor',
  'text-decoration-line': 'none',
  'text-decoration-skip': 'revert',
  'text-decoration-skip-ink': 'revert',
  'text-decoration-style': 'solid',
  'text-decoration-thickness': 'auto',
  'text-emphasis': 'revert',
  'text-emphasis-color': 'revert',
  'text-emphasis-position': 'revert',
  'text-emphasis-style': 'revert',
  'text-indent': 'revert',
  'text-justify': 'revert',
  'text-orientation': 'revert',
  'text-overflow': 'clip',
  'text-rendering': 'revert',
  'text-shadow': 'revert',
  'text-size-adjust': 'revert',
  'text-transform': 'revert',
  'text-underline-offset': 'revert',
  'text-underline-position': 'revert',
  'text-wrap': 'revert',
  'text-wrap-mode': 'revert',
  'text-wrap-style': 'revert',
  'timeline-scope': 'none',
  top: 'auto',
  'touch-action': 'auto',
  transform: 'none',
  'transform-box': 'view-box',
  'transform-origin': '50% 50% 0',
  'transform-style': 'flat',
  transition: '0s 0s all ease normal',
  'transition-behavior': 'normal',
  'transition-delay': '0s',
  'transition-duration': '0s',
  'transition-property': 'all',
  'transition-timing-function': 'ease',
  translate: 'none',
  'unicode-bidi': 'normal',
  'user-select': 'auto',
  'vertical-align': 'baseline',
  'view-timeline': 'none block',
  'view-timeline-axis': 'block',
  'view-timeline-inset': 'auto',
  'view-timeline-name': 'none',
  'view-transition-name': 'none',
  visibility: 'revert',
  'white-space': 'revert',
  'white-space-collapse': 'revert',
  widows: 'revert',
  width: 'auto',
  'will-change': 'auto',
  'word-break': 'revert',
  'word-spacing': 'revert',
  'word-wrap': 'revert',
  'writing-mode': 'revert',
  'z-index': 'auto',
  zoom: 'normal',
};
